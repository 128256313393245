import React from 'react';
import PropTypes from 'prop-types';
import styles from './description.module.scss';

const Description = (props) => {
  
  return (
    <div className={styles.container}>

      {
        props.title &&
        <h1 className={styles.title}>{props.title}</h1>
      }

      {
        props.courseName &&
        <h1 className={styles.courseName}>{props.courseName}</h1>
      }

      {
        props.scholarity &&
        <p className={styles.scholarity}>{props.scholarity}</p>
      }


      {
        props.imageUrl &&
        <div className={styles.media}>
          <img src={props.imageUrl.publicURL} alt={props.imageUrl.name} />
        </div>  
      }

      <div className={styles.mainContent}>
        {
          props.shortDescription &&
          props.shortDescription.map((text, index) => {
            return (
              <p key={`${index}-${text.substring(1, 10)}`} className={styles.paragraph}>{text}</p>
            );
          })
        }

        {
          props.topics &&
          <div>
            <div className={styles.yellowFragment}></div>
            <p>Alguns dos tópicos do curso de {props.courseName} são:</p>
            <ul className={styles.topics}>
              {props.topics.map((topic, index) => {
                return (
                  <li key={`${index}-${topic}`} >{topic}</li>
                );
              })}
            </ul>
          </div>
        }

        {
          props.longDescription &&
          <React.Fragment>
            <div className={styles.yellowFragment}></div>
            {props.longDescription.map((text, index) => {
              return (
                <p key={`${index}-${text.substring(1, 10)}`} className={styles.paragraph}>{text}</p>
              );
            })}
          </React.Fragment>
        }

        {
          props.additionalInfo &&
          <React.Fragment>
            <div className={styles.yellowFragment}></div>
            {props.additionalInfo.map((text, index) => {
              return (
                <p key={`${index}-${text.substring(1, 10)}`} className={styles.paragraph}>{text}</p>
              );
            })}
          </React.Fragment>
        }
      </div>
    </div>
  );
};

export default Description;

Description.propTypes = {
  title: PropTypes.string,
  courseName: PropTypes.string,
  scholarity: PropTypes.string,
  imageUrl: PropTypes.shape({
    name: PropTypes.string,
    publicURL: PropTypes.string,
  }),
  shortDescription: PropTypes.arrayOf(PropTypes.string),
  topics: PropTypes.arrayOf(PropTypes.string),
  longDescription: PropTypes.arrayOf(PropTypes.string),
  additionalInfo: PropTypes.arrayOf(PropTypes.string),
};
