import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Button, Checkbox, Chip, CircularProgress, FormControl, FormControlLabel, IconButton, InputLabel, ListItemText, makeStyles, MenuItem, Radio, RadioGroup, Select, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DeleteIcon from '@material-ui/icons/Delete';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from "date-fns/locale/pt";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useAuth0 } from "@auth0/auth0-react";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './react-phone-input-2-override.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3ebff0',
    }
  },
  typography: {
    fontFamily: 'Poppins, sans-serif'
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: '10px'
      }
    }
  }
});

export const StyledTextField = ({ onChange, max, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <TextField
        variant="outlined"
        onChange={(event) => onChange(event.target.value)}
        inputProps={ max ? { maxLength: max } : {}}
        {...props}
      />
    </ThemeProvider>
  );
};

StyledTextField.propTypes = {
  onChange: PropTypes.func,
  max: PropTypes.number
};

export const StyledDatePicker = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        <DatePicker
          autoOk
          inputVariant="outlined"
          format="yyyy/MM/dd"
          {...props}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export const StyledSelect = ({ label, options, className, onChange, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <FormControl variant="outlined" className={className}>
        <InputLabel>{label}</InputLabel>
        <Select
          label={label}
          variant="outlined"
          onChange={(event) => onChange(event.target.value)}
          {...props}
        >
          {options.map((option) => {
              return <MenuItem key={option} value={option}>{option}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

StyledSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  onChange: PropTypes.func
};

export const StyledMultiSelect = ({ label, value, options, className, onChange, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <FormControl variant="outlined" className={className}>
        <InputLabel>{label}</InputLabel>
        <Select
          label={label}
          value={value}
          multiple
          variant="outlined"
          onChange={(event) => onChange(event.target.value)}
          renderValue={(value) => value.join(', ')}
          // MenuProps={{ disableScrollLock: true }}
          {...props}
        >
          {options.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={value && value.indexOf(option) >= 0} color="primary" />
                  <ListItemText primary={option} />
                </MenuItem>
              );
          })}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

StyledMultiSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  onChange: PropTypes.func
};

export const StyledTags = ({ label, onChange, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        multiple
        freeSolo
        autoSelect
        options={[]}
        onChange={(event, values) => onChange(values)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip key={option} size="small" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
          />
        )}
        {...props}
      />
    </ThemeProvider>
  );
};

StyledTags.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func
};

export const StyledCheckbox = ({ label, value, onChange, className }) => {
  const classes = makeStyles({
    label: {
      padding: '8px 0'
    }
  })();

  return (
    <ThemeProvider theme={theme}>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={(event) => onChange(event.target.checked)}
            color="primary"
          />
        }
        label={label}
        className={className}
        classes={{
          label: classes.label
        }}
      />
    </ThemeProvider>
  );
};

StyledCheckbox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string
};

export const StyledCheckboxes = ({ value, options, onChange, className }) => {
  return (
    <ThemeProvider theme={theme}>
      {options.map((option) =>
        <FormControlLabel
          key={option}
          control={
            <Checkbox
              checked={value.includes(option)}
              onChange={(event) => {
                const newValue = value.filter(v => v !== option);

                if (event.target.checked) {
                  newValue.push(option);
                }

                onChange(newValue);
              }}
              color="primary"
            />
          }
          label={option}
          className={className}
        />
      )}
    </ThemeProvider>
  );
};

StyledCheckboxes.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  className: PropTypes.string
};

export const StyledRadios = ({ value, options, onChange, className }) => {
  return (
    <ThemeProvider theme={theme}>
      <RadioGroup value={value} onChange={(event) => onChange(event.target.value)} className={className}>
        {options.map((option) =>
          <FormControlLabel
            key={option}
            control={<Radio color="primary" />}
            label={option}
            value={option}
          />
        )}
      </RadioGroup>
    </ThemeProvider>
  );
};

StyledRadios.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  className: PropTypes.string
};

export const StyledFile = ({ id, onChange, className, courseId }) => {
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState();
  const { getAccessTokenSilently } = useAuth0();

  return (
    <ThemeProvider theme={theme}>
      <input
        id={`file-upload${id}`}
        type="file"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={async (event) =>  {
          setLoading(true);

          const formData = new FormData();
          formData.append('id', courseId);
          formData.append('file', event.target.files[0]);

          const response = await fetch(`${process.env.GATSBY_API_URL}/api/file`, {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${await getAccessTokenSilently()}`
            },
            body: formData
          });

          // reset input
          inputRef.current.value = null;

          if (!response.ok) {
            onChange(null);
            setFilename(null);
            setLoading(false);
            console.error("An error has occured.", response.status);
            return;
          }

          try {
            const json = await response.json();
            onChange(json.url);
            setFilename(json.name);
            setLoading(false);
          } catch (error) {
            onChange(null);
            setFilename(null);
            setLoading(false);
            console.error("An error has occured.", error);
          }
        }}
      />
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
        <label htmlFor={`file-upload${id}`} className={className}>
          <Button variant="contained" color="primary" component="span" disabled={loading} style={{ width: 100 }}>
            {loading ? <CircularProgress size={16} style={{ margin: 4 }} /> : "Upload"}
          </Button>
        </label>
        {filename &&
          <>
            <span style={{ marginLeft: '16px', marginRight: '4px', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{filename}</span>
            <IconButton component="span" size="small" onClick={() => { onChange(null); setFilename(null); }}>
              <DeleteIcon />
            </IconButton>
          </>
        }
      </div>
    </ThemeProvider>
  );
};

StyledFile.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  courseId: PropTypes.string
};

export const StyledPhoneField = ({ value, onChange, label, className }) => {
  const [focused, setFocused] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <div className={className}>
        <PhoneInput
          value={value ? value : "351"}
          onChange={(value, country, e, formattedValue) => {
            if (value === country.dialCode) {
              onChange(null);
            } else {
              onChange(formattedValue);
            }
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          masks={{
            pt: "... ... ..."
          }}
          countryCodeEditable={false}
          specialLabel={label || ""}
          containerClass={focused ? 'focused' : ''}
        />
      </div>
    </ThemeProvider>
  );
};

StyledPhoneField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string
};
