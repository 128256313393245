import React from 'react';
import PropTypes from 'prop-types';
import EmptySpace from '@components/ui/empty-space';
import Spinner from '@components/ui/spinner';
import styles from './submission.module.scss';

// fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

export const AlreadyApplied = () => {
  return (
    <div className={styles.container}>
      <div className={styles.primary}>
        <FontAwesomeIcon icon={faTimes} className={styles.error}/>
        <span>Já te candidataste a este curso.</span>
      </div>
      <div className={styles.secondary}>A tua candidatura está em análise. Os resultados serão anunciados por email, num prazo máximo de 5 dias após o término do prazo de candidatura.</div>
      <EmptySpace desktop={{ margin: 60, isLineVisible: false }} mobile={{ margin: 30, isLineVisible: false }} />
    </div>
  );
};

const Submission = ({ loading, error }) => {
  return (
    <div className={styles.container}>
      {loading &&
        <Spinner />
      }
      {!loading && !error &&
        <>
          <div className={styles.primary}>
            <FontAwesomeIcon icon={faCheck} />
            <span>Candidatura submetida com sucesso.</span>
          </div>
          <div className={styles.secondary}>Os resultados das candidaturas serão anunciados por email, num prazo máximo de 5 dias após o término do prazo de candidatura.</div>
        </>
      }
      {!loading && error &&
        <>
          <div className={styles.primary}>
            <FontAwesomeIcon icon={faTimes} className={styles.error}/>
            <span>Erro a submeter a candidatura.</span>
          </div>
          <div className={styles.secondary}>Por favor tenta outra vez.</div>
        </>
      }
      <EmptySpace desktop={{ margin: 60, isLineVisible: false }} mobile={{ margin: 30, isLineVisible: false }} />
    </div>
  );
};

export default Submission;

Submission.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool
};
