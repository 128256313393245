import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './progress.module.scss';

const Progress = ({ step, stepCount, text }) => {
  const canvasRef = useRef();
  
  const r = 11;
  const line = 43;

  const canvasWidth = stepCount*r*2 + (stepCount-1)*line + r;
  const canvasHeight = r*2 + r;

  // canvas width and height will boost with higher DPR
  // canvas css style will make sure it has the proper dimentions
  const dpr = window.devicePixelRatio || 1;

  const black = "#000000";
  const white = "#FFFFFF";
  const yellow = "#feee00";
  const gray = "#b8b8b8";

  useEffect(() => {
    const c = canvasRef.current;
    const ctx = c.getContext("2d");

    // clear
    ctx.fillStyle = white;
    ctx.fillRect(0, 0, c.width, c.height);

    // scale for HiDPI screens
    ctx.scale(dpr, dpr);

    // center + aliasing
    ctx.translate(r*0.5, r*0.5);
    ctx.translate(0.5, 0.5);

    // line border
    ctx.lineWidth = 2;
    
    // draw junctions
    for (let s = 1; s < stepCount; s++) {
      ctx.beginPath();
      ctx.moveTo(r*2 + (r*2+line)*(s-1), r);
      ctx.lineTo(r*2+line + (r*2+line)*(s-1), r);
      ctx.strokeStyle = step >= s+1 ? black : gray;
      ctx.stroke(); 
    }
    
    // draw circles
    for (let s = 0; s < stepCount; s++) {
      ctx.beginPath();
      ctx.arc(r + (r*2+line)*s, r, r, 0, 2 * Math.PI);
      ctx.fillStyle = step >= s+1 ? yellow : white;
      ctx.fill();
      ctx.strokeStyle = step >= s+1 ? black : gray;
      ctx.stroke();
    }
    
    // revert center + aliasing
    ctx.translate(-r*0.5, -r*0.5);
    ctx.translate(-0.5, -0.5);

    // revert scale
    ctx.scale(1.0/dpr, 1.0/dpr);
  }, [step]);
  
  return (
    <div className={styles.container}>
      <canvas
        ref={canvasRef}
        width={canvasWidth * dpr}
        height={canvasHeight * dpr}
        style={{
          width: canvasWidth,
          height: canvasHeight
        }} />
      <p>{text(step, stepCount)}</p>
    </div>
  );
};

export default Progress;

Progress.propTypes = {
  step: PropTypes.number,
  stepCount: PropTypes.number,
  text: PropTypes.func
};
