import React from 'react';
import PropTypes from 'prop-types';
import Form from '../form';

const Application = ({ courseId, questions, application, setApplication }) => {
  return (
    <Form
      header="Para completares a tua candidatura, responde às seguintes perguntas. Nota que ao concluíres este passo, a tua candidatura é submetida. Portanto, confirma tudo antes de carregares em 'continuar'."
      courseId={courseId}
      questions={questions}
      values={application}
      onChange={setApplication}
    />
  );
};

export default Application;

Application.propTypes = {
  courseId: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
          description: PropTypes.string,
          type: PropTypes.string,
          options: PropTypes.arrayOf(PropTypes.string),
          multiple: PropTypes.bool,
          required: PropTypes.bool,
          max: PropTypes.number,
        })
      )
    })
  ),
  application: PropTypes.object,
  setApplication: PropTypes.func
};
