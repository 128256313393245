import React, { useEffect, useState } from 'react';
import Layout from '@components/domain/shared/layout';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { graphql, Link, navigate } from "gatsby";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Description from '@components/domain/course-details/description';
import EmptySpace from '@components/ui/empty-space';
import PropTypes from 'prop-types';
import Spacing from '@components/ui/spacing';
import styles from '@styles/templates/curso-candidatura.module.scss';
import Progress from '@components/domain/course-application/progress';
import Profile from '@components/domain/course-application/profile';
import Application from '@components/domain/course-application/application';
import Spinner from '@components/ui/spinner';
import { useAuth0 } from "@auth0/auth0-react";
import Submission, { AlreadyApplied } from '@components/domain/course-application/submission';

const CursoCandidatura = (props) => {
  const [ step, setStep ] = useState(1);
  const [ user, setUser ] = useState();
  const [ profile, setProfile ] = useState({});
  const [ application, setApplication ] = useState({});
  const [ errors, setErrors ] = useState([]);
  const [ submissionError, setSubmissionError ] = useState();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const { data: { course } } = props;
  const breakpoints = useBreakpoint();

  // scroll to top on step change
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [step]);

  // load user profile
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    (async () => {
      try {
        const token = await getAccessTokenSilently();

        const response = await fetch(`${process.env.GATSBY_API_URL}/api/session`, {
          method: "GET",
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          console.error("An error has occured.", response.status);
          return;
        }

        const json = await response.json();

        setUser(json);

        // init profile form with existing data
        if (json.profile) {
          setProfile({
            ...json.profile
          });
        } else {
          if (json.name !== json.email) {
            setProfile({
              name: json.name,
              email: json.email
            });
          } else {
            setProfile({
              email: json.email
            });
          }
        }
      } catch (error) {
        console.error("An error has occured.", error);
      }
    })();
  }, [isAuthenticated]);

  // submit application
  useEffect(() => {
    // only submit on step 3
    // only submit if not previously submitted
    if (step !== 3 || submissionError === true || submissionError === false) {
      return;
    }

    (async () => {
      try {
        const token = await getAccessTokenSilently();
  
        const data = {
          id: course.general.courseID,
          profile: profile,
          application: application
        };
  
        const response = await fetch(`${process.env.GATSBY_API_URL}/api/application`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(data)
        });
  
        if (!response.ok) {
          console.error("An error has occured.", response.status);
          setSubmissionError(true);
          return;
        }
  
        setSubmissionError(false);
        setStep(3);
      } catch (error) {
        console.error("An error has occured.", error);
      }
    })();
  }, [step, submissionError]);

  const validate = (group, field, value) => {
    var errors = [];

    // required
    if (field.required && (!value || (Array.isArray(value) && value.length === 0))) {
      errors.push({ type: "required", label: field.label, message: <>O campo <b>{group.title} &gt; {field.label}</b> é obrigatório.</> });
    }

    // max length
    if (field.max && value?.length > field.max) {
      errors.push({ type: "max", label: field.label, message: <>O campo <b>{group.title} &gt; {field.label}</b> excede o tamanho máximo de {field.max} caracteres.</> });
    }

    // number
    if (field.type === "number") {
      const re = /^-?\d+(\.\d+)?$/;
      if (value && !re.test(value)) {
        errors.push({ type: "number", label: field.label, message: <>O campo <b>{group.title} &gt; {field.label}</b> não contém um número válido.</> });
      }
    }

    // email
    if (field.type === "email") {
      // email regexp from chromium
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value && !re.test(value)) {
        errors.push({ type: "email", label: field.label, message: <>O campo <b>{group.title} &gt; {field.label}</b> não contém um e-mail válido.</> });
      }
    }

    // phone
    if (field.type === "phone") {
      if (value) {
        // remove all but numbers
        const cleaned = value.replace(/[^0-9.]/g, '');
        // if Portugal, validate if has 9 digits
        if (cleaned.startsWith("351") && cleaned.length !== 12) {
          errors.push({ type: "email", label: field.label, message: <>O campo <b>{group.title} &gt; {field.label}</b> não contém um telefone válido.</> });
        }
      }
    }

    return errors;
  };

  const onPreviousStep = () => {
    setErrors([]);

    // go to courses page
    if (step === 1) {
      navigate('/candidatura/');
    }

    setStep(step > 1 ? step-1 : 1);
  };

  const onNextStep = () => {
    var errors = [];

    // validate step 1
    if (step === 1) {
      props.data.profile.questions.map(group => {
        group.fields.map(field => {
          errors = [ ...errors, ...validate(group, field, profile[field.id]) ];
        });
      });
    }

    // validate step 2
    if (step === 2) {
      course.questions?.map(group => {
        group.fields.map(field => {
          errors = [ ...errors, ...validate(group, field, application[field.id]) ];
        });
      });
    }

    setErrors(errors);

    if (errors.length === 0) {
      setStep(step < 3 ? step+1 : 3);
    }
  };

  // check if user already applied to this activity
  const alreadyApplied = user?.activities?.includes(course.general.courseID);

  return (
    <Layout extraFooterPadding={breakpoints.mobile ? 200 : 0}>
      <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />
      <div className={styles.container}>
        {user && !alreadyApplied && step < 3 &&
        <Spacing>
          <Progress step={step} stepCount={2} text={(step, stepCount) => `Passo ${step} de ${stepCount}`}/>
        </Spacing>
        }
        <EmptySpace desktop={{ margin: 30 }} mobile={{ margin: 20 }} />
        <Spacing>
          <div className={styles.metadata}>
            <section className={styles.overview}>
              <Description
                title="Candidatura"
                courseName={course.description.title}
                scholarity={course.description.scholarity}
              />
            </section>
          </div>
        </Spacing>
        <Spacing>
          {!user && <Spinner />}
          {user && alreadyApplied &&
          <>
            <AlreadyApplied />
            <div className={styles.controls}>
              <a onClick={() => navigate('/candidatura/')}>Voltar à página anterior</a>
            </div>
          </>
          }
          {user && !alreadyApplied &&
          <>
            {step === 1 && <Profile courseId={course.general.courseID} questions={props.data.profile.questions || []} profile={profile} setProfile={setProfile} />}
            {step === 2 && <Application courseId={course.general.courseID} questions={course.questions || []} application={application} setApplication={setApplication} />}
            {step === 3 && <Submission loading={submissionError === undefined} error={submissionError} />}
            {errors.length > 0 &&
            <div className={styles.errors}>
              <div className={styles.title}>Erros de validação:</div>
              <ul>
              {errors.map((error, i) =>
                <li key={i}>{error.message}</li>
              )}
              </ul>
            </div>
            }
            {(step === 1 || step === 2) &&
            <div className={styles.controls}>
              <div className={styles.back}>
                <a onClick={onPreviousStep} disabled={step === 1}>Voltar</a>
              </div>
              <a className={styles.continue} onClick={onNextStep}>continuar</a>
            </div>
            }
            {step === 3 &&
            <div className={styles.controls}>
              <Link to="/">Voltar à página inicial</Link>
            </div>
            }
          </>
          }
        </Spacing>
      </div>
      <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />
    </Layout>
  );
};

export default withAuthenticationRequired(CursoCandidatura);

export const pageQuery = graphql`
  query CourseApplicationQuery($jsonFileId: String) {
    course: coursesJson(id: {eq: $jsonFileId}) {
      id
      description {
        title
        scholarity
      }
      general {
        courseID
      }
      questions {
        title
        fields {
          id
          label
          description
          type
          options
          multiple
          required
          max
        }
      }
    }
    profile: profileJson {
      questions {
        title
        fields {
          id
          label
          description
          type
          options
          multiple
          required
          max
        }
      }
    }
  }
`;

CursoCandidatura.propTypes = {
  path: PropTypes.string,
  data: PropTypes.shape({
    course: PropTypes.shape({
      description: PropTypes.shape({
        title: PropTypes.string,
        scholarity: PropTypes.string
      }),
      general: PropTypes.shape({
        courseID: PropTypes.string
      }),
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          fields: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              label: PropTypes.string,
              description: PropTypes.string,
              type: PropTypes.string,
              options: PropTypes.arrayOf(PropTypes.string),
              disabled: PropTypes.bool,
              multiple: PropTypes.bool,
              required: PropTypes.bool,
              max: PropTypes.number
            })
          )
        })
      )
    }),
    profile: PropTypes.shape({
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          fields: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              label: PropTypes.string,
              type: PropTypes.string,
              options: PropTypes.arrayOf(PropTypes.string),
              disabled: PropTypes.bool,
              required: PropTypes.bool,
              max: PropTypes.number
            })
          )
        })
      )
    })
  })
};
