import React from 'react';
import PropTypes from 'prop-types';
import EmptySpace from '@components/ui/empty-space';
import { StyledTextField, StyledDatePicker, StyledMultiSelect, StyledSelect, StyledTags, StyledCheckbox, StyledCheckboxes, StyledRadios, StyledFile, StyledPhoneField } from '@components/ui/material-ui';
import styles from './form.module.scss';

const Form = ({ courseId, header, questions, values, onChange }) => {

  const renderField = ({ id, label, description, type, options, multiple, required, max }) => {
    const commonProps = (defaultValue, className) => {
      return {
        value: values[id] || defaultValue,
        onChange: (value) => onChange({ ...values, [id]: value }),
        className: className || styles.input
      };
    };

    // mark label with required
    label = required ? `${label} *` : label;

    var item;
    switch (type) {
      case "text":
        item = <StyledTextField {...commonProps("")} type="text" max={max} />;
        break;
      case "number":
        item = <StyledTextField {...commonProps("")} type="number" max={max} />;
        break;
      case "email":
        item = <StyledTextField {...commonProps("")} type="email"  max={max} />;
        break;
      case "phone":
        item = <StyledPhoneField {...commonProps("")} />;
        break;
      case "area":
        item = <StyledTextField {...commonProps("")} multiline rows={4} max={max}/>;
        break;
      case "date":
        item = <StyledDatePicker {...commonProps(null)} />;
        break;
      case "select":
        if (multiple) {
          item = <StyledMultiSelect {...commonProps([])} options={options} />;
        } else {
          item = <StyledSelect {...commonProps("")} options={options} />;
        }
        break;
      case "tags":
        item = <StyledTags {...commonProps("")} />;
        break;
      case "check":
        if (multiple) {
          item = <StyledCheckboxes {...commonProps([], styles.check)} options={options} />;
        } else {
          item = <StyledCheckbox {...commonProps(false, styles.check)} label={label} />;
        }
        break;
      case "radio":
        item = <StyledRadios {...commonProps("", styles.radio)} options={options} />;
        break;
      case "file":
        item = <StyledFile {...commonProps([], styles.file)} id={id} courseId={courseId} />;
        break;
      default:
        item = <span>...</span>;
    }

    // single check have custom rendering
    if (type === "check" && multiple !== true) {
      return (
        <div key={id} className={`${styles.field} ${styles.checkField}`}>
          {item}
          { description &&
            <span className={`${styles.description} ${styles.checkDescription}`} dangerouslySetInnerHTML={{ __html: description }} />
          }
        </div>
      );
    }

    return (
      <div key={id} className={styles.field}>
        <span className={styles.label}>{label}</span>
        { description &&
        <span className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
        }
        {item}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <p>{header}</p>
      <p>* Campos obrigatórios</p>
      <EmptySpace desktop={{ margin: 60 }} mobile={{ margin: 30 }} />
      {questions.map(group => 
        <div key={group.title}>
          <p className={styles.title}>{group.title}</p>
          {group.fields.map(field => renderField(field))}
          <EmptySpace desktop={{ margin: 120, isLineVisible: true }} mobile={{ margin: 60, isLineVisible: true }} />
        </div>
      )}
    </div>
  );
};

export default Form;

Form.propTypes = {
  courseId: PropTypes.string,
  header: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
          description: PropTypes.string,
          type: PropTypes.string,
          options: PropTypes.arrayOf(PropTypes.string),
          multiple: PropTypes.bool,
          required: PropTypes.bool,
          max: PropTypes.number,
        })
      )
    })
  ),
  values: PropTypes.object,
  onChange: PropTypes.func
};
